
@import url('https://use.typekit.net/lui1evw.css');

@tailwind base;

h1 {
  @apply text-4xl font-bold mt-6 mb-6;
}

h2 {
  @apply text-3xl font-bold mt-6 mb-6;
}

h3 {
  @apply text-2xl font-bold mt-6 mb-6;
}

h4 {
  @apply text-xl font-bold mt-6 mb-6;
}

h5 {
  @apply text-lg font-bold mt-6 mb-6;
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CirceRounded';
  font-weight: 400;
  src: url('/assets/fonts/CirceRounded-Regular.otf');
}

@font-face {
  font-family: 'CirceRounded';
  font-weight: 700;
  src: url('/assets/fonts/CirceRounded-Bold.otf');
}

@font-face {
  font-family: 'CirceRounded';
  font-weight: 800;
  src: url('/assets/fonts/CirceRounded-ExtraBold.otf');
}

@font-face {
  font-family: 'CirceRounded';
  font-weight: 200;
  src: url('/assets/fonts/CirceRounded-ExtraLight.otf');
}

@font-face {
  font-family: 'CirceRounded';
  font-weight: 300;
  src: url('/assets/fonts/CirceRounded-Light.otf');
}

@font-face {
  font-family: 'CirceRounded';
  font-weight: 100;
  src: url('/assets/fonts/CirceRounded-Thin.otf');
}

.common-button {
  @apply hover:opacity-50 disabled:opacity-60 disabled:cursor-not-allowed
}

main {
  @apply min-h-screen relative
}

header {
  @apply fixed top-0 left-0 right-0 flex justify-between items-center
}

footer {
  @apply bg-accent-blue
}

.h-main-screen {
  @apply h-[calc(100vh-100px)]
}

.h-main-screen-m {
  @apply h-[calc(100vh-13vw)]
}